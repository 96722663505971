import { InjectionToken } from '@angular/core';
import {
  CurrencyInputConfig
} from '../../modules/invoice/blocks/page/edit-invoice/invoice-editor/ui/invoice-table-input/models/currency-input.config';
import { environment } from '../../../environments/environment';

export const WINDOW = new InjectionToken<Window>('Global window object', {
  factory: () => window,
});

export const MIN_DROPDOWN_HEIGHT = 300;

export enum DialogCloseEvents {
  CREATED = 'Created',
  CANCELED = 'Canceled',
  DELETED = 'Deleted',
}

export const FILESTORE_PREFIX = `${environment.fileStorePrefix}`;
export const FILESTORE_DNS = `${environment.fileStoreDNS}`;

export const ICONS = [
  { name: 'luxcore-news', url: '../assets/menu-icons/news.svg' },
  { name: 'luxcore-absence', url: '../assets/menu-icons/absence.svg' },
  { name: 'luxcore-activity', url: '../assets/menu-icons/activity.svg' },
  { name: 'luxcore-chat', url: '../assets/menu-icons/chat.svg' },
  { name: 'luxcore-company', url: '../assets/menu-icons/company.svg' },
  { name: 'luxcore-fin-report', url: '../assets/menu-icons/fin-report.svg' },
  { name: 'luxcore-advance-report', url: '../assets/menu-icons/advance-report.svg' },
  {
    name: 'luxcore-company-type',
    url: '../assets/menu-icons/company-type.svg',
  },
  {
    name: 'luxcore-scheduler',
    url: '../assets/menu-icons/scheduler.svg',
  },
  { name: 'luxcore-shift', url: '../assets/menu-icons/shift.svg' },
  { name: 'luxcore-contact', url: '../assets/menu-icons/contact.svg' },
  { name: 'luxcore-contract', url: '../assets/menu-icons/contract.svg' },
  { name: 'luxcore-project', url: '../assets/menu-icons/project.svg' },
  { name: 'luxcore-department', url: '../assets/menu-icons/department.svg' },
  { name: 'luxcore-document', url: '../assets/menu-icons/document.svg' },
  {
    name: 'luxcore-industry-type',
    url: '../assets/menu-icons/industry-type.svg',
  },
  { name: 'luxcore-invoice', url: '../assets/menu-icons/invoice.svg' },
  { name: 'luxcore-act', url: '../assets/menu-icons/invoice.svg' }, // Temporary alias to prevent error
  { name: 'luxcore-payment', url: '../assets/menu-icons/payment.svg' },
  { name: 'luxcore-remittance', url: '../assets/menu-icons/remittance.svg' },
  { name: 'luxcore-rights', url: '../assets/menu-icons/rights.svg' },
  { name: 'luxcore-users', url: '../assets/menu-icons/users.svg' },
  { name: 'luxcore-profile', url: '../assets/menu-icons/profile.svg' },
  { name: 'luxcore-exit', url: '../assets/menu-icons/exit.svg' },
  { name: 'luxcore-task', url: '../assets/menu-icons/tasks.svg' },
  { name: 'luxcore-board', url: '../assets/menu-icons/board.svg' },
  { name: 'luxcore-todo', url: '../assets/menu-icons/todo.svg' },
  { name: 'luxcore-tag', url: '../assets/menu-icons/tag.svg' },
  { name: 'luxcore-act', url: '../assets/menu-icons/act.svg' },
  { name: 'luxcore-kanbanboard', url: '../assets/menu-icons/kanbanboard.svg' },
  { name: 'luxcore-union', url: '../assets/menu-icons/union.svg' },
  { name: 'luxcore-workplace', url: '../assets/menu-icons/workplace.svg' },
  {
    name: 'luxcore-info',
    url: '../assets/modules/company-edit/img/info-icon.svg',
  },
  {
    name: 'luxcore-connections',
    url: '../assets/menu-icons/connections.svg',
  },
  { name: 'luxcore-company-popup', url: '../assets/company/company.svg' },
  { name: 'luxcore-chain', url: '../assets/menu-icons/chain.svg' },
  { name: 'luxcore-saved', url: '../assets/menu-icons/saved.svg' },
  { name: 'luxcore-filter', url: '../assets/modules/company/img/filter.svg' },
  { name: 'luxcore-edrpou', url: '../assets/menu-icons/edrpou.svg' },
  { name: 'luxcore-bell', url: '../assets/modules/chat/img/bell-ring.svg' },
  { name: 'luxcore-trash', url: '../assets/modules/chat/img/trash.svg' },
  {
    name: 'luxcore-bell-off',
    url: '../assets/modules/chat/img/bell-ring-off.svg',
  },
  {
    name: 'luxcore-create-chat',
    url: '../assets/modules/chat/img/create-chat.svg',
  },
  {
    name: 'luxcore-add-member',
    url: '../assets/modules/chat/img/add-member.svg',
  },
  {
    name: 'luxcore-circle-bell',
    url: '../assets/modules/chat/img/circle-bell-ring.svg',
  },
  {
    name: 'luxcore-circle-bell',
    url: '../assets/modules/chat/img/circle-bell-ring-off.svg',
  },
  {
    name: 'luxcore-circle-bell-off',
    url: '../assets/modules/chat/img/bell-ring-off.svg',
  },
  {
    name: 'luxcore-quote-left',
    url: '../assets/modules/chat/img/quote-left.svg',
  },
  {
    name: 'luxcore-check-double',
    url: '../assets/modules/chat/img/check-double.svg',
  },
  { name: 'luxcore-product', url: '../assets/menu-icons/shopping.svg' },
  { name: 'luxcore-placement', url: '../assets/menu-icons/placement.svg' },
  { name: 'luxcore-canceled', url: '../assets/placement-stages/canceled.svg' },
  { name: 'luxcore-changing', url: '../assets/placement-stages/changing.svg' },
  { name: 'luxcore-closed', url: '../assets/placement-stages/closed.svg' },
  { name: 'luxcore-finished', url: '../assets/placement-stages/finished.svg' },
  { name: 'luxcore-pausing', url: '../assets/placement-stages/pausing.svg' },
  { name: 'luxcore-approved', url: '../assets/modules/fin-report/approved.svg' },
  { name: 'luxcore-approving', url: '../assets/modules/fin-report/approving.svg' },
  { name: 'luxcore-reporting', url: '../assets/modules/fin-report/approving.svg' },
  { name: 'luxcore-reported', url: '../assets/modules/fin-report/approved.svg' },
  {
    name: 'luxcore-providing',
    url: '../assets/placement-stages/providing.svg',
  },
  { name: 'luxcore-rejected', url: '../assets/placement-stages/rejected.svg' },
  { name: 'luxcore-starting', url: '../assets/placement-stages/starting.svg' },
  { name: 'luxcore-stopped', url: '../assets/placement-stages/stopped.svg' },
  { name: 'luxcore-virtual', url: '../assets/placement-stages/virtual.svg' },
  { name: 'luxcore-draft', url: '../assets/placement-stages/draft.svg' },
  {
    name: 'luxcore-approving',
    url: '../assets/placement-stages/approving.svg',
  },
  { name: 'luxcore-file', url: '../assets/file-icons/file.svg' },
  { name: 'luxcore-doc', url: '../assets/file-icons/doc.svg' },
  { name: 'luxcore-image', url: '../assets/file-icons/image.svg' },
  { name: 'luxcore-pdf', url: '../assets/file-icons/pdf.svg' },
  { name: 'luxcore-ppt', url: '../assets/file-icons/ppt.svg' },
  { name: 'luxcore-xls', url: '../assets/file-icons/xls.svg' },
  { name: 'luxcore-zip', url: '../assets/file-icons/zip.svg' },
  { name: 'luxcore-download', url: '../assets/file-icons/download-file.svg' },
  { name: 'luxcore-upload', url: '../assets/file-icons/upload-file.svg' },
  { name: 'luxcore-audio', url: '../assets/file-icons/audio.svg' },
  { name: 'luxcore-video', url: '../assets/file-icons/video.svg' },
  { name: 'luxcore-file-error', url: '../assets/file-icons/file-error.svg' },

  { name: 'luxcore-comments-like', url: '../assets/comments/like.svg' },
  { name: 'luxcore-comments-dislike', url: '../assets/comments/dislike.svg' },
  { name: 'luxcore-comments-reply', url: '../assets/comments/reply.svg' },
  { name: 'luxcore-comments-more', url: '../assets/comments/more-btn.svg' },

  {
    name: 'luxcore-invoice-plus',
    url: '../assets/modules/invoice/editor/plus-blue.svg',
  },
  { name: 'luxcore-groups', url: '../assets/menu-icons/group.svg' },
  {
    name: 'luxcore-yellow-start',
    url: '../assets/modules/company/img/star-yellow.svg',
  },
  { name: 'luxcore-toggle', url: '../assets/menu-icons/toggle-button.svg' },
  {
    name: 'luxcore-toggle-active',
    url: '../assets/menu-icons/toggle-button-active.svg',
  },
  {
    name: 'luxcore-organization',
    url: '../assets/menu-icons/organization.svg',
  },
  { name: 'luxcore-account', url: '../assets/menu-icons/account.svg' },
  { name: 'company-debtor', url: '../assets/company/company-debtor.svg' },
  {
    name: 'bad-company-debtor',
    url: '../assets/company/bad-company-debtor.svg',
  },
  {
    name: 'company-active-client',
    url: '../assets/company/company-active-client.svg',
  },
  { name: 'former-client', url: '../assets/company/former-client.svg' },
  { name: 'need-update', url: '../assets/company/need-update.svg' },
  {
    name: 'service-desk',
    url: '../assets/modules/service-desk/service-desk.svg',
  },
  {
    name: 'luxcore-folder',
    url: '../assets/menu-icons/folder.svg',
  },

  //filter icons
  { name: 'luxcore-address', url: '../assets/filter-icons/address.svg' },
  { name: 'luxcore-personal', url: '../assets/filter-icons/personal-info.svg' },
  { name: 'luxcore-favorites', url: '../assets/filter-icons/favorites.svg' },
  { name: 'luxcore-comments', url: '../assets/filter-icons/comments.svg' },
  { name: 'luxcore-debt', url: '../assets/filter-icons/debt.svg' },
  {
    name: 'luxcore-description',
    url: '../assets/filter-icons/description.svg',
  },
  { name: 'luxcore-free', url: '../assets/filter-icons/free.svg' },
  { name: 'luxcore-ids', url: '../assets/filter-icons/IDs.svg' },
  { name: 'luxcore-manager', url: '../assets/filter-icons/manager.svg' },
  { name: 'luxcore-source', url: '../assets/filter-icons/source.svg' },
  { name: 'luxcore-status', url: '../assets/filter-icons/status.svg' },
  { name: 'luxcore-sale', url: '../assets/filter-icons/sale.svg' },

  //reminders icons
  {
    name: 'luxcore-reminder-bell',
    url: '../assets/modules/shared/bell.svg',
  },
  {
    name: 'luxcore-reminder-bell-inactive',
    url: '../assets/modules/shared/bell-inactive.svg',
  },
  {
    name: 'luxcore-reminder-bell-active',
    url: '../assets/modules/shared/bell-active.svg',
  },

  //fin-report
  {
    name: 'luxcore-stars',
    url: '../assets/modules/fin-report/stars.svg'
  },
  {
    name: 'work-item',
    url: '../assets/menu-icons/work-item.svg'
  },
  {
    name: 'work-item-in-user',
    url: '../assets/menu-icons/work-item-in-user.svg'
  },

  //news
  { name: 'luxcore-fire', url: '../assets/modules/news/fire.svg' },
  { name: 'luxcore-check', url: '../assets/modules/news/check.svg' },
  { name: 'luxcore-pin', url: '../assets/modules/news/pin.svg' },

  //shared icons
  { name: 'luxcore-link', url: '../assets/modules/shared/link.svg' },
  { name: 'luxcore-qrcode', url: '../assets/widgets/qrcode.svg' },
  { name: 'luxcore-wifi', url: '../assets/widgets/wifi.svg' },

  //shift
  { name: 'luxcore-disabled', url: '../assets/modules/shift/disabled.svg' },

  //activity
  { name: 'luxcore-vacation', url: '../assets/modules/absence/img/sun-absence.svg' },
  { name: 'luxcore-sick', url: '../assets/modules/absence/img/sick.svg' },
];

export const currencyConfig: CurrencyInputConfig = {
  prefix: '',
  suffix: '',
  thousands: ' ',
  decimal: '.',
  align: 'center',
  allowNegative: false,
};

export const Breakpoints = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 900,
  xl: 1366,
  xxl: 1680,
} as const;
